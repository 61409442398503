export const divesites = [
  {
    id: 1,
    name: "Park Reef",
    depth: "12 meters / 40 Feet",
    img: "https://i.ibb.co/r3sH3Rv/image17.webp",
    desc: "Enjoy a pleasant easy dive spot. Park Reef features some interesting rock formations overgrown with corals. Here you will have a chance at finding some lobsters, squids and even turtles that spend their time here. Because of the bottom this dive site usually has good visibility even when conditions aren't the best",
  },
  {
    id: 2,
    name: "Wreck Monica",
    depth: "15 meters / 50 Feet",
    img: "https://i.ibb.co/HKrJCBP/image27.webp",
    desc: "Is a French cargo ship from the 1880s that transported materials for the construction of the Panama Canal but it came too close to our coasts and ran aground on our reefs. Today we can see the complete prop and parts of the machine, the boiler among others parts, also materials for the construction of railroad tracks, small caves were created between the ship and the reef a little narrow but very beautiful. Monica is at a depth of 15 meters maximum and minimum 5, this is 1/2 mile from the coast",
  },
  {
    id: 3,
    name: "Shark Point",
    depth: "30 meters / 100 Feet",
    img: "https://i.ibb.co/QPdMxdN/Ginglymostoma-cirratum.webp",
    desc: "This dive is reserved for our more advanced divers as we will be going down to approximently 24 to 30 meters. Here we will be able to explore a huge coral garden on reefs that are barely seen by other divers in the area. As the name says, here we are likely to run in to some nurse sharks and black tip reef sharks. If you haven't done your advance open water yet, here is your chance.",
  },
  {
    id: 4,
    name: "El Nino",
    depth: "6 meters / 20 Feet",
    img: "https://i.ibb.co/BgCP1zG/image13.webp",
    desc: "Ideal conditions for beginner divers and divers who are just looking to refresh their skills. Despite this dive being one of the shallowest comparing to others, El Niño impresses with lots of wonderful schools of tropical fish.",
  },
  {
    id: 5,
    name: "Submarine",
    depth: "6 meters / 20 Feet",
    img: "https://i.ibb.co/QCT9WPG/image7.webp",
    desc: "Was created to make excursions by an Russian engineer. The submarine was in service from 1994 until 2004 when hurricane George passed thru the Dominican Republic and it sunk just a 1/4 mile off the shores of Cabeza de Toro. Since then it has became a sanctuary for the divers, its dimensions are 50 by 15 and depth of 5 to 7 meters.",
  },
  {
    id: 6,
    name: "Anchor",
    depth: "19 meters / 62 Feet",
    img: "https://i.ibb.co/0CqyM68/image16.webp",
    desc: "The anchor The anchor ed a reef with a depth of 19 meters and a minimum of 14, that's where Monica's sailors dropped the anchor when they realized that it was very close to the dog coast, it was too late, in this reef we can find the chain from the freighter's anchor Monica the anchor was pulled out of the water by the natives",
  },
  {
    id: 7,
    name: "Cuevitas",
    depth: "10 meters / 33 Feet",
    img: "https://i.ibb.co/qrQyxgd/St-Johns-Cave-Systems-223127777.webp",
    desc: "Discover the thrills of swimming through the canyons and small caves that make up Cuevitas. All the swim throughts are illuminated by enough nature light that their is no need for flashlights, but they are welcome as you will be able to find some of the lobsters and cleaner shrimp hiding inside the caves. As well this site offers a variety of different corals and fishes to be seen.",
  },
  {
    id: 8,
    name: "Finger Coral Plantation",
    depth: "6 meters / 20 Feet",
    img: "https://i.ibb.co/QmwgGVC/photo7.webp",
    desc: "This place is beautiful and very interesting, it has a depth of 6 meters, the bottom is sandy and with very large rocks where corals are born. The interesting thing about this place in the coral nursery that we have created to stake out in all our dive sites, also we have a lot of yellowtail snapper fish among others.",
  },
  {
    id: 9,
    name: "Rock City",
    depth: "16 meters / 53 Feet",
    img: "https://i.ibb.co/W569x6Z/image9.webp",
    desc: "Here in Rock City there is a depth between 5 meters (17 feet) upto 16 meters (53 feet) because of the formation of the canyons that lay in the natural reef. This is also the reason that it is so calm on the beach of cabeza de toro. When the conditions are permitting this site is an amazing experience to do drift dives.",
  },
  {
    id: 10,
    name: "St. George Wreck - Bayahibe",
    depth: "40 meters / 131 Feet",
    img: "https://i.ibb.co/gSQ8PvM/st-george-shipwreck-2.webp",
    desc: "Located just a half-mile off the coast of Dominicus in Bayahíbe, the 73-meter (240 feet) long St. George ship was originally built in 1962 in Scotland, and was used to transport wheat and barley from Europe to the Caribbean. Today, the sunken ship is home to barracudas, moray eels, king mackerels, and groupers, among other species. Due to its depth of 40 meters (131 feet), it is recommended only for advanced divers.",
  },
  {
    id: 11,
    name: "The Wall - Catalina",
    depth: "30 meters / 100 Feet",
    img: "https://i.ibb.co/DV6B74f/1280px-The-Aquarium-Catalina-Island.webp",
    desc: "This spectacular drop-off is definitely one of the best dive sites in the Dominican Republic.  A gentle slope leads towards the wall. On the top reef you will enjoy the abundant marine life. Every square centimetre of this sea bed is covered with sponges and hard or soft coral. The depth of this terrace varies from 1.5 to 6 meters. The drop-off is fully overgrown with coral and the crevices hide a tremendous amount of reef creatures. We restrict the depth even for advanced divers to 30 meters. ",
  },
  {
    id: 12,
    name: "Atlantic Princess - Bayahibe",
    depth: "10 meters / 33 Feet",
    img: "https://i.ibb.co/54dGmwf/photo5.webp",
    desc: "The Atlantic Princess was a small cruise ship used by tourists of the area. In 2008, it ran aground on the beach during Tropical Storm Fay. The plan was to sink the ship, and create a beautiful underwater environment and artificial reef for visitors to enjoy. But the Atlantic Princess actually sunk on its own on May 6, 2009, and rests at just 12 meters (40 feet) below the surface. To this day, it remains a great dive spot for all, beginners to experts, thanks to its abundant marine life at shallow depths.",
  },
]
